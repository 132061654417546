import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import firebase from "firebase/app";
import "firebase/auth";
import { DataService } from '../../data.service'
import { ProfileService } from '../../profile/profile.service';
import { RoleService } from 'src/app/auth/role.service';
import { environment } from '../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import * as moment from 'moment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit {
  userName: any;
  Role: string
  userId: string;
  login: boolean = true;
  userImage: any;
  companyName: any;
  allowedPermission = [];
  showWalkThrough: any;
  userInfo: any;
  companyLogo: any;
  trialMessage: string;
  activePlanFeatures: any;
  totalExpiryRemaining: number = 0;
  isAdmin: boolean;
  planName: any = '';
  publicUrl: any = '';
  isCandidate: boolean;
  notificationList: any = [];
  candidateId: any;
  companyId: any;
  isLoading: boolean = true;
  assessmentURL: any = '';
  planExpiryMessage: string = '';

  constructor(public router: Router,
    private location: LocationStrategy,
    private _dataService: DataService,
    private _profileService: ProfileService,
    private _roleService: RoleService,
    private cookieService: CookieService
  ) {
    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => { history.pushState(null, null, window.location.href) });
  }

  async ngOnInit() {
    let { tenantId, user_id } = await this._dataService.getAuthenticatedUser();
    let session = this.cookieService.get('__session');
    let tenantid = (this.cookieService.get('tenantId') != '') ? this.cookieService.get('tenantId') : tenantId;
    if (session != '' || tenantId != '') {
      await this.getData(tenantid, user_id);
    }
    //  else {
    //   console.log('redirect to login');
    //   firebase.auth().signOut();
    //   sessionStorage.clear();
    //   this.cookieService.deleteAll();
    //   this.router.navigate(['signin']);
    // }
  }

  async getData(tenantId, userId) {
    if (await this._roleService.checkAuthentication(tenantId) == true || userId != '') {
      let { user_id,
        tenantId,
        isInterviewer,
        isHRManager,
        isCandidate,
        isAdmin,
        isHRExecutive,
        profile_pic,
        table_prefix,
        candidate_id,
        name,
        role
      } = await this._dataService.getAuthenticatedUser();

      if (user_id != undefined) {
        this.userImage = profile_pic;
        this.userName = name
        this.Role = role;
        this.isAdmin = isAdmin;
        this.isCandidate = isCandidate;

        if (this.router.url == '/signin') {
          if (isInterviewer) {
            this.router.navigate(['interviews'])
          } else if (isHRManager || isHRExecutive) {
            this.router.navigate(['jobs'])
          } else if (isCandidate) {
            this.router.navigate(['employee/dashboard']);
          } else {
            this.router.navigate(['dashboard']);
          }
        }

        let permission = await this._roleService.menuAllowed(this.Role);
        this.allowedPermission = permission.allowedMenu;

        let details: any = await this._dataService.getDetailsWithId(`${environment.tenantCollection}`, tenantId);
        this.companyLogo = details['companyLogo'];
        this.publicUrl = details['publicUrl'];
        this.companyId = details['companyId'];
        this.companyName = details['displayName'];
        this.assessmentURL = details['assessmentUrl'];
        if (this.Role == 'Candidate') {
          return;
        }

        if (details.hasOwnProperty('activePlanInfo')) {
          let activePlan = details['activePlanInfo'].activePlan;
          let planInfo: any = await this._dataService.getDetailsWithId(`${environment.mainCollection}/${tenantId}/UserPayments/`, activePlan);
          if (planInfo.status == 'on-grace') {
            let numberOfDays = await this.numberOfDaysLeftInBillingCycle(planInfo.last_expiry_date);
            if (numberOfDays <= 10) {
              this.planExpiryMessage = `Grace period expires on: ${new Date(planInfo.last_expiry_date).toUTCString().slice(0, 17)}`;
            }
          } else if (planInfo.status == 'active') {
            let numberOfDays = await this.numberOfDaysLeftInBillingCycle(planInfo.expiry_date);
            if (numberOfDays <= 10) {
              this.planExpiryMessage = `Plan expires on: ${new Date(planInfo.expiry_date).toUTCString().slice(0, 17)}`;
            }
          } else if (planInfo.status == 'expired') {
            this.planExpiryMessage = `Plan expired!`;
          }
        }

        if (!isCandidate) {
          let selectedPlan = await this._dataService.UserPlanCheck();
          if (selectedPlan.hasOwnProperty('totalExpiryDays') && selectedPlan.status == false) {
            this.totalExpiryRemaining = selectedPlan.totalExpiryDays;
          } else {
            this.planName = selectedPlan.plan_name;
          }
        }

        if (isCandidate) {
          this.notificationList = await this._dataService.getDetailsWithField(`${table_prefix}/Notifications`, 'candidate_id', candidate_id);
        }

      } else {
        this.login = false;
      }

      this._profileService.refreshImageNeeded$.subscribe(async (image) => {
        this.userImage = image
      })

      this._profileService.refreshLogoNeeded$.subscribe(async (logo) => {
        this.companyLogo = logo;
      })

      this._profileService.currentMessage.subscribe(message => {
        if (message != '') {
          this.planName = message;
        }
        if (message == 'cancelled') {
          this.planName = '';
        }
      })
    }
  }

  async numberOfDaysLeftInBillingCycle(expiryDate) {
    const newStart = moment(new Date().toISOString().substring(0, 10));
    const newEnd = moment(new Date(expiryDate).toISOString().substring(0, 10));
    let numberOfDays = Math.abs(newStart.diff(newEnd, 'days'));
    return numberOfDays;
  }

  signOut() {
    sessionStorage.clear();
    this.cookieService.delete('__session', '/', environment.domain);
    this.cookieService.delete('tenantId', '/', environment.domain);
    this.cookieService.delete('__session', '/candidate', environment.domain);
    this.cookieService.delete('tenantId', '/candidate', environment.domain);
    this.cookieService.delete('__session', '/employee', environment.domain);
    this.cookieService.delete('tenantId', '/employee', environment.domain);
    firebase.auth().signOut();
    if (this.isCandidate == true) {
      this.router.navigate(['signin'], { queryParams: { companyId: this.companyId, companyName: this.companyName } });
    } else {
      this.router.navigate(['signin'])
    }
  }
}
