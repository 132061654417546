import { Component, Input, OnInit } from '@angular/core';
import EnjoyHint from 'xbs-enjoyhint/src/enjoyhint'
import { Router } from '@angular/router'
import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from '../../../environments/environment';
declare const $: any;

@Component({
  selector: 'app-walkthrough',
  templateUrl: './walkthrough.component.html',
  styleUrls: ['./walkthrough.component.css']
})
export class WalkthroughComponent implements OnInit {

  @Input() userDetails;
  constructor(private router: Router, private firestore: AngularFirestore) { }

  ngOnInit(): void {
    this.runEnjoy(this.userDetails);
  }


  runEnjoy(details) {
    var that = this;
    var enjoyhint_instance = new EnjoyHint({
      onStart: function () {
        //do something
      },

      onSkip: function () {
        details['walkthrough'] = false;
        let ortherinfo = {
          'modified_at': new Date().toISOString(),
          'modified_by': details.user_id
        }
        details['OtherInfo'] = ortherinfo;
        console.log('walk ', details)
        that.updateDetails(details);
      },
      onNext: function () {
        //do something
      }
    });

    const onboardingBtn = document.querySelector(".onboarding-btn");
    const enjoyhint_script_steps = [
      {
        "next #main-banner": 'Hello, I\'d like to tell you about Hireme.<br> Click "Next" to proceed.'
      },
      {
        "next #add-user": 'You can now Add User and invite them to be a part of HireMe Journey.<br> Click "Next" to proceed.'
      },
      {
        "next #button-new-user": 'Click on +New User button to add new user.<br> Click "Next" to proceed.'
      },
      {
        "next #add-new-user-details": 'Add User details and save.<br> Click "Next" to proceed.'
      },
      {
        "next #button-add-job-opening": 'Click on +New Job button to add new job opening.<br> Click "Next" to proceed.'
      },
      {
        "next #add-job-opening-details": 'Fill all details and save.<br> Click "Next" to proceed.'
      },
      {
        "next #fill-similar-details": 'You can add Candidates, Interviews, Clients.<br> Click "Next" to proceed.'
      },
      {
        "next #open-settings": 'You can also update the setting of your account.<br> Click "Next" to proceed.'
      },
      {
        "skip #goto-settings": 'Update the setting here.<br> Click "End Tour" to proceed to Dashboard.',
        showNext: false
      },
    ];

    //set script config
    enjoyhint_instance.set(enjoyhint_script_steps);

    console.log('enjoyhint_instance ', enjoyhint_instance)
    //run Enjoyhint script
    enjoyhint_instance.run();

    onboardingBtn.addEventListener("click", () => {
      const enjoyhint_instance = new EnjoyHint({});
      enjoyhint_instance.set(enjoyhint_script_steps);
      enjoyhint_instance.run();
    });

  }

  async updateDetails(details) {
    await this.firestore.collection(`${environment.mainCollection}/${details.tenant_id}/Users`).doc(`${details.user_id}`).update(details);
    sessionStorage.setItem('userInfo', JSON.stringify([details]))
    window.location.href = "/dashboard";
  }

}
