import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { TextEditorComponent } from './text-editor/text-editor.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { MultiselectComponent } from './multiselect/multiselect.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { GetUserNameByIdPipe } from './get-user-name-by-id.pipe';
import { SafePipe } from './safe.pipe';
import { ShowRatingsComponent } from './show-ratings/show-ratings.component';
import { CommentBoxComponent } from './comment-box/comment-box.component';
import { FollowFeatureComponent } from './follow-feature/follow-feature.component';
import { WalkthroughComponent } from './walkthrough/walkthrough.component';
import { PaginationComponent } from './pagination/pagination.component';
import { MaterialModule } from './../material/material.module';
import { RenameByIdPipe } from './rename-by-id.pipe';
import { ConfigureAutomatedRatingModuleComponent } from './configure-automated-rating-module/configure-automated-rating-module.component';
import { ConvertInCurrencyPipe } from './convert-in-currency.pipe';
import { DefaultTemplateComponent } from './templates/default-template/default-template.component';
@NgModule({
  declarations: [
    FileUploadComponent,
    TextEditorComponent,
    MultiselectComponent,
    GetUserNameByIdPipe,
    SafePipe,
    ShowRatingsComponent,
    CommentBoxComponent,
    FollowFeatureComponent,
    WalkthroughComponent,
    PaginationComponent,
    RenameByIdPipe,
    ConfigureAutomatedRatingModuleComponent,
    ConvertInCurrencyPipe,
    DefaultTemplateComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CKEditorModule,
    NgMultiSelectDropDownModule,
    MaterialModule
  ],
  providers: [ShowRatingsComponent],
  exports: [
    FileUploadComponent,
    TextEditorComponent,
    MultiselectComponent,
    GetUserNameByIdPipe,
    SafePipe,
    RenameByIdPipe,
    ShowRatingsComponent,
    CommentBoxComponent,
    FollowFeatureComponent,
    WalkthroughComponent,
    PaginationComponent,
    ConfigureAutomatedRatingModuleComponent,
    ConvertInCurrencyPipe,
    DefaultTemplateComponent
  ],
})
export class SharedModule { }
