// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyAjYeWdVUcNdSL9DxsqZjKTCVrYRP_X8m0",
    authDomain: "hireme-uat.firebaseapp.com",
    projectId: "hireme-uat",
    storageBucket: "hireme-uat.appspot.com",
    messagingSenderId: "437066331620",
    appId: "1:437066331620:web:99d73539c4f7b93fef03f1",
    measurementId: "G-PLT85YZ010"
  },
  emailUrl: 'https://uat.hireme.cloud',
  adminRight: ['Admin', 'HRManager', 'HRExecutive'],
  serviceUrl: 'https://us-central1-hireme-uat.cloudfunctions.net/',
  paymentUrl: 'https://qiehe68t10.execute-api.us-east-1.amazonaws.com/dev/api/v1',
  msApi: 'https://graph.microsoft.com/v1.0/drive/root:',
  oneDriveFolder: 'HireMe',
  senderMail: 'no-reply@hireme.cloud',
  mainCollection: 'HiremeApp',
  tenantCollection: 'HiremeTenants',
  configCollection: 'HiremeConfig',
  rolesAllowedToHRManager: ['HRManager', 'HRExecutive', 'Interviewer'],
  rolesAllowedToHRExecutive: ['HRExecutive', 'Interviewer'],
  interviewersRole: ['Interviewer'],
  razorPayId: 'rzp_test_NKOyV1OMJN0AGP', // rzp_live_0cLTyZZQaPR8eI
  defaultPlan: 'plan_MWBSaxA4Ne7uA13zqjxS',
  defaultPlanName: "Hireme Basic Monthly",
  salesforceUrl: 'https://iejigzq15h.execute-api.us-east-1.amazonaws.com/dev',
  domain: '.hireme.cloud',
  allowOrigin: 'https://uat.hireme.cloud',
  msLoginClientId: '107ca9d1-2c46-4e0c-92c2-e1de3ed88695',
  msLoginRedirectUrl: 'https://uat.hireme.cloud',
  parseToken: '89bdec5cc7b3e9e73f9b5475124b194e'
};

/*

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
