import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { first } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import firebase from "firebase/app";

@Injectable({
  providedIn: 'root'
})


export class DataService {
  serviceUrl = environment.serviceUrl;
  msApi = environment.msApi;
  afterTrialMessageToAdmin = 'Your trial is expired. Please activate your account or contact support.';
  afterTrialMessageToOthers = 'Please contact to your adminsitrator!';
  noPlansMessage = 'Please choose any plan to enjoy benefits!';

  constructor(private firestore: AngularFirestore, private _http: HttpClient, public router: Router, private cookieService: CookieService) { }

  ///////////////////////////////............File upload on onederive.............//////////////////////////
  async getOneDriveUrl(uid) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
    let data = { id: uid };
    let result = await this._http.post(`${this.serviceUrl}/getAccessToken`, data, httpOptions).toPromise();

    return result;
  }

  async getTokenFromCode(auth_code, user_id) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'code': auth_code
      })
    }
    let data = { id: user_id }
    let result = await this._http.post(`${this.serviceUrl}/getTokenFromCode`, data, httpOptions).toPromise();
    return result;
  }

  async uploadToOnedrive(data, ext, name, type, tenant_id) {
    let token = sessionStorage.getItem("onedriveAccess_token");
    let onedrive_folder = environment.oneDriveFolder;
    let unique_name = `${tenant_id}_${name}_${type}`;
    let onedrive_filename = `${unique_name}.${ext}`
    const httpOptions = {
      headers: {
        'Authorization': "Bearer " + token,
        // 'Content-Type': "application/json", // When you use old version, please modify this to "mime.lookup(file)",
      }
    }
    console.log('onedrive_filename ', onedrive_filename)
    console.log(`${this.msApi}/${onedrive_folder}/${onedrive_filename}:/content`);
    let result = await this._http.put(`${this.msApi}/${onedrive_folder}/${onedrive_filename}:/content`, data, httpOptions).toPromise();
    return result;
  }
  //////////////////////////////////////////////////......End........///////////////////////////////////////////////////

  async getDetailsWithId(collectionName: any, id: string) {
    return await this.firestore.collection(collectionName).doc(id).valueChanges({ idField: 'id' }).pipe(first()).toPromise();
  }

  async getDetailsWithField(collectionName: any, field: string, value: string) {
    return await this.firestore.collection(collectionName, ref => ref.where(field, '==', value)).valueChanges({ idField: 'id' }).pipe(first()).toPromise();
  }

  async getData(collectionName: any) {
    let getdetails = await this.firestore.collection(collectionName, ref => ref.orderBy('payment_date', 'desc')).valueChanges({ idField: 'id' }).pipe(first()).toPromise();
    return getdetails;
  }

  async getPlanDetails(collectionName: any, id) {
    let getdetails = await this.firestore.collection(collectionName).doc(id).valueChanges({ idField: 'id' }).pipe(first()).toPromise();
    return getdetails;
  }

  async getAllRoles(collectionName) {
    return await this.firestore.collection(collectionName).snapshotChanges();
  }

  async getAllUsers(collectionName) {
    return await this.firestore.collection(collectionName, ref => ref.where('role', 'in', ['HRExecutive', 'HRManager','Admin'])).snapshotChanges();
  }

  async getAuthenticatedUser() {
    let userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
    if (userInfo != null && userInfo.length > 0) {
      return {
        table_prefix: `${environment.mainCollection}/${userInfo[0].tenant_id}`,
        role: userInfo[0].role,
        isAdmin: (userInfo[0].role == 'Admin') ? true : false,
        isHRManager: (userInfo[0].role == 'HRManager') ? true : false,
        isHRExecutive: (userInfo[0].role == 'HRExecutive') ? true : false,
        isInterviewer: (userInfo[0].role == 'Interviewer') ? true : false,
        isCandidate: (userInfo[0].role == 'Candidate') ? true : false,
        user_id: userInfo[0].user_id,
        candidate_id: userInfo[0].candidate_id,
        name: `${userInfo[0].first_name} ${userInfo[0].last_name}`,
        email: userInfo[0].email,
        tenantId: userInfo[0].tenant_id,
        profile_pic: userInfo[0].profile_pic,
        domain: userInfo[0].domain,
        walkthrough: userInfo[0].walkthrough
      };
    } else {
      return {}
    }
  }

  async UserPlanCheck() {
    let { table_prefix, tenantId, isAdmin, isHRExecutive, isHRManager } = await this.getAuthenticatedUser();
    let paymentCheck: any = await this.getDetailsWithId(`${environment.tenantCollection}`, tenantId);

    let endDate = new Date(paymentCheck['OtherInfo']['trial_expiry_date']).toDateString();
    const start = moment(new Date().toISOString().substring(0, 10));
    const end = moment(paymentCheck['OtherInfo']['trial_expiry_date']);
    const numberOfDays = Math.abs(start.diff(end, 'days'));
    const currentActivePlan = paymentCheck['activePlanInfo'] != undefined ? paymentCheck['activePlanInfo']['activePlan'] : 'Basic';
    let planCheck: any = await this.getDetailsWithId(`${table_prefix}/UserPayments`, currentActivePlan);
    let customizeFeatures = (paymentCheck.CustomizationRequestFeatures != undefined) ? paymentCheck.CustomizationRequestFeatures : '';

    if (paymentCheck['OtherInfo'].hasOwnProperty('trial_expiry_date') && paymentCheck['OtherInfo']['trial_expiry_date'] < new Date().toISOString()) {

      //if trial periods ends & user purchase the plan
      if (planCheck.status == 'expired' || planCheck.status == 'cancelled' || planCheck.status == 'created') {
        if (isAdmin) {
          this.router.navigate(['settings/plans'], {
            state: { Message: (planCheck.status == 'created') ? this.afterTrialMessageToAdmin : this.noPlansMessage, plan_name: paymentCheck['activePlanInfo']['activePlan'] }
          });
        } else if (isHRExecutive || isHRManager) {
          this.router.navigate(['settings/notifications'], { state: { Message: this.afterTrialMessageToOthers } });
        } else {
          this.router.navigate(['settings/profile'], { state: { Message: this.afterTrialMessageToOthers } });
        }

        return { status: false, message: (planCheck.status == 'created') ? this.afterTrialMessageToAdmin : this.noPlansMessage, totalExpiryDays: 0, plan_name: paymentCheck['activePlanInfo']['activePlan'], plan_customization: customizeFeatures };
      }

      return { status: true, plan_id: planCheck['plan_id'], plan_name: planCheck['plan_name'], plan_customization: customizeFeatures };

    } else {
      //if trial perios not ends & user purchase the plan
      let message = `Your Trial period expires on ${endDate}.To continue the uninterrupted access to your account, please purchase the plan before trial period ends.`;

      if (Object.keys(planCheck).length > 0) {
        if (planCheck['status'] == 'active' || planCheck['status'] == 'on-grace') {
          return { status: true, plan_id: planCheck['plan_id'], plan_name: planCheck['plan_name'], plan_customization: customizeFeatures };
        }

        if (planCheck['status'] == 'created') {
          return { status: false, message: message, plan_id: planCheck['plan_id'], totalExpiryDays: numberOfDays, plan_name: planCheck['plan_name'], plan_customization: customizeFeatures };
        }

        if (planCheck['status'] == 'cancelled') {
          return { status: false, message: message, plan_id: planCheck['plan_id'], totalExpiryDays: numberOfDays, plan_name: planCheck['plan_name'], plan_customization: customizeFeatures };
        }

        if (planCheck['status'] == 'expired') {
          if (isAdmin) {
            await this.router.navigate(['settings/plans'], {
              state: { Message: this.noPlansMessage }
            });
          } else if (isHRExecutive || isHRManager) {
            this.router.navigate(['settings/notifications'], { state: { Message: this.afterTrialMessageToOthers } });
          }

          return { status: false, plan_name: paymentCheck['activePlanInfo']['activePlan'],plan_customization: customizeFeatures };
        }

      } else {

        if (isAdmin) {
          this.router.navigate(['settings/plans'], {
            state: { Message: this.noPlansMessage }
          });
        } else if (isHRExecutive || isHRManager) {
          this.router.navigate(['settings/notifications'], { state: { Message: this.afterTrialMessageToOthers } });
        } else {
          this.router.navigate(['settings/profile'], { state: { Message: this.afterTrialMessageToOthers } });
        }

        return { status: false, totalExpiryDays: numberOfDays, plan_customization: customizeFeatures };
      }
    }
  }


  async getPlanFeatures(id, feature) {
    let planFeatures: any = await this.getDetailsWithId(`${environment.configCollection}`, 'PLAN_CONFIG');
    let result = planFeatures.plans.find(item => item.planId == id);
    if (feature != 'All' && result != undefined && feature != 'plan_features') {
      return result.features.find(item => Object.keys(item).includes(feature));
    }
    return (result != undefined) ? result : 'NoPlans';
  }
}