import { Component } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  page_title = '';
  constructor(private titleService: Title,  public router: Router) {
    router.events.subscribe(event => {
      if (event instanceof RoutesRecognized) {
        let current_route = event.state.root.firstChild;
        this.page_title = current_route?.data.page_title;
        if (this.page_title == undefined) {
          let title = current_route.data.title;
          this.titleService.setTitle(title);
        }
        else {
          titleService.setTitle(this.page_title);
        }
      }
    });
  }
}
